import type {Placement} from '@react-types/overlays';
import clsx from 'clsx';
import {FC, ReactNode} from 'react';
import {Header, Menu, MenuItem, MenuSection, MenuTrigger, Popover} from 'react-aria-components';

import {Ripple} from '../ripple';
import styles from './Dropdown.module.css';

type RootProps = {
  placement?: Placement;
  className?: string | undefined;
  children: ReactNode;
};

const DropdownRoot: FC<RootProps> = ({placement, className, children}) => {
  return (
    <Popover placement={placement}>
      <Menu className={clsx(styles.root, className)}>{children}</Menu>
    </Popover>
  );
};

type TriggerProps = {
  /** ドロップダウンを開いた / 閉じた状態にする. 制御コンポーネントになる */
  open?: boolean | undefined;
  /** ドロップダウンを開いた状態を既定にする. 非制御コンポーネントになる */
  defaultOpen?: boolean | undefined;
  children: ReactNode;
};

const DropdownTrigger: FC<TriggerProps> = ({open, defaultOpen, children}) => {
  return (
    <MenuTrigger isOpen={open} defaultOpen={defaultOpen}>
      {children}
    </MenuTrigger>
  );
};

type GroupProps = {
  /** グループのタイトル */
  title: string;
  className?: string | undefined;
  children: ReactNode;
};

const DropdownGroup: FC<GroupProps> = ({title, className, children}) => {
  return (
    <MenuSection className={clsx(styles.group, className)}>
      <Header className={styles.header}>{title}</Header>
      {children}
    </MenuSection>
  );
};

type ItemProps = {
  destructive?: boolean | undefined;
  onClick?: (() => void) | undefined;
  className?: string | undefined;
  children: ReactNode;
};

const DropdownItem: FC<ItemProps> = ({destructive, onClick, className, children}) => {
  return (
    <MenuItem className={clsx(styles.item, destructive && styles.destructive, className)} onAction={onClick}>
      {children}
      <Ripple />
    </MenuItem>
  );
};

export const Dropdown = Object.assign(DropdownRoot, {
  Trigger: DropdownTrigger,
  Group: DropdownGroup,
  Item: DropdownItem,
});
