import * as Icon from '@bitkey-service/workhub-icons';
import {FC, memo} from 'react';

import styles from './Loading.module.css';

type Props = {
  centered?: boolean | undefined;
};

const _Loading: FC<Props> = ({centered}) => {
  const inner = (
    <span className={styles.root}>
      <Icon.Loading />
    </span>
  );

  if (centered) {
    return <div className={styles.centered}>{inner}</div>;
  }

  return inner;
};

export const Loading = memo(_Loading);
