import {useFocusable, useFocusRing} from '@react-aria/focus';
import {mergeProps, useObjectRef} from '@react-aria/utils';
import {forwardRef, type ForwardRefRenderFunction, type ReactElement} from 'react';

import styles from './Focusable.module.css';

type Props = {
  disabled?: boolean | undefined;
  autoFocus?: boolean | undefined;
  children: ReactElement;
};

const _Focusable: ForwardRefRenderFunction<HTMLElement, Props> = ({autoFocus, disabled, children}, ref) => {
  ref = useObjectRef(ref);

  const {focusableProps} = useFocusable(
    {
      autoFocus,
      isDisabled: disabled,
    },
    ref
  );

  const {focusProps, isFocused, isFocusVisible} = useFocusRing();

  return (
    <span
      {...mergeProps(focusableProps, focusProps)}
      ref={ref}
      className={styles.root}
      data-focused={isFocused || null}
      data-focus-visible={isFocusVisible || null}
      tabIndex={0}
    >
      {children}
    </span>
  );
};

/**
 * インタラクティブでない要素でフォーカスおよびホバー操作をサポートするためのコンポーネント.
 * このパッケージで実装されたボタン等以外でも, これを使うことでツールチップが表示できるようになる.
 */
export const Focusable = forwardRef(_Focusable);
