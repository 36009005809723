import {HintOutline} from '@bitkey-service/workhub-icons';
import type {Placement} from '@react-types/overlays';
import type {FC, ReactNode} from 'react';

import {Focusable} from '../focusable';
import {Tooltip} from '../tooltip';

type Props = {
  placement?: Placement | undefined;
  children: ReactNode;
};

export const Hint: FC<Props> = ({placement, children}) => {
  return (
    <Tooltip.Trigger>
      <Focusable>
        <HintOutline fontSize='small' />
      </Focusable>
      <Tooltip placement={placement}>{children}</Tooltip>
    </Tooltip.Trigger>
  );
};
