import clsx from 'clsx';
import {type ComponentProps, forwardRef, type ForwardRefRenderFunction, type PropsWithChildren, useMemo} from 'react';
import {Button as ButtonBase, type PressEvent} from 'react-aria-components';

import {Ripple} from '../ripple';
import {Tooltip} from '../tooltip';
import styles from './IconButton.module.css';

type FormProps = Pick<
  ComponentProps<typeof ButtonBase>,
  'type' | 'form' | 'formAction' | 'formEncType' | 'formMethod' | 'formNoValidate' | 'formTarget' | 'name' | 'value'
>;

type Props = FormProps &
  PropsWithChildren<{
    /** アイコンボタンのタイトル. ボタンの役割を一言で説明する. "編集" "削除" など. この文字列はツールチップにも表示される */
    title: string;
    /** より詳細な説明が必要なときに記載する. これを設定した場合はツールチップにはタイトルを表示せず, 説明文が優先される */
    description?: string;
    /** ボタンの背景を着色するかどうか */
    background?: boolean;
    /** 破壊的な操作を表すかどうか */
    disruptive?: boolean;
    /** ボタンが無効かどうか */
    disabled?: boolean;
    /** ツールチップを無効にする. アイコンのラベルは引き続き有効なので, 読み上げは実施される. */
    disableTooltip?: boolean;
    className?: string;
    onClick?: (e: PressEvent) => void;
  }>;

const _IconButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  {
    title,
    description,
    background = false,
    disruptive = false,
    disabled,
    disableTooltip,
    className,
    onClick,
    children,
    ...props
  },
  ref
) => {
  const inner = useMemo(
    () => (
      <ButtonBase
        {...props}
        ref={ref}
        className={clsx(styles.root, className)}
        isDisabled={disabled}
        aria-label={title}
        data-background={background || null}
        data-disruptive={disruptive || null}
        onPress={onClick}
      >
        {children}
        <Ripple />
      </ButtonBase>
    ),
    [ref, className, disabled, title, background, disruptive, onClick, children]
  );

  if (disableTooltip) {
    return inner;
  }

  return (
    <Tooltip.Trigger>
      {inner}
      <Tooltip>
        {/* タイトルを表示する場合は重複して読み上げられないよう, ツールチップ内のテキストを aria-hidden にする */}
        {description || <span aria-hidden={true}>{title}</span>}
      </Tooltip>
    </Tooltip.Trigger>
  );
};

export const IconButton = forwardRef(_IconButton);
