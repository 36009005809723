import type {Placement} from '@react-types/overlays';
import type {FC, PropsWithChildren} from 'react';
import {Tooltip as TooltipBase, TooltipTrigger as TooltipTriggerBase} from 'react-aria-components';

import styles from './Tooltip.module.css';

type RootProps = {
  /** ツールチップを表示する位置 */
  placement?: Placement;
};

const TooltipRoot: FC<PropsWithChildren<RootProps>> = ({placement, children}) => {
  return (
    <TooltipBase className={styles.root} placement={placement}>
      {children}
    </TooltipBase>
  );
};

type TriggerProps = {
  /** ツールチップを開いた状態にする. 制御コンポーネントとなる. */
  open?: boolean;
  /** ツールチップを開いた状態を既定とする. 非制御コンポーネントとなる. */
  defaultOpen?: boolean;
};

const TooltipTrigger: FC<PropsWithChildren<TriggerProps>> = ({open, children}) => {
  return (
    <TooltipTriggerBase delay={0} closeDelay={0} isOpen={open}>
      {children}
    </TooltipTriggerBase>
  );
};

export const Tooltip = Object.assign(TooltipRoot, {
  Trigger: TooltipTrigger,
});
