import styled from '@emotion/styled';
import MuiTextField from '@mui/material/TextField';
import {TextButton} from '@workhub/ui';
import React, {useCallback, useState} from 'react';
import {z} from 'zod';

import {
  searchSsoServicesByOrgCodeApi,
  SearchSsoServicesByOrgCodeResponse,
} from '@/api-call/workhub-core/searchSsoServicesByOrgCodeApi';
import {SsoIdProviders, SsoKeys, SsoOperations} from '@/common/constant/cookie';
import {addCookieValueByKey} from '@/common/storage/cookie';
import {WHFont, WHFontCss} from '@/common/styles/whFont';
import WorkhubLogoIcon from '@/components/icon/WorkhubLogoIcon';
import {StartFlowRes} from '@/v2_api/workhub-core/oidc/sso/apiOidcSso';
import {ApiWorkhubCoreSso} from '@/v2_api/workhub-core/sso/apiSso';
import {OidcSsoService} from '@/v2_service/oidc/sso/oidcSsoService';

import useDict from '../../common/hooks/useDict';
import {Locale} from '../../common/redux/state-types/localeStateType';
import WLoadingComponent from '../../components/figma/others/stepper/WLoadingComponent';

type OidcStartFlowRes = StartFlowRes & {
  type: 'oidc';
};

type SamlStartFlowRes = {
  type: 'saml';
  code: string;
  loginUrl: string;
};

interface P {
  transitionLoginScreen: () => void;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const LoginFormArea = styled.div`
  width: 385px;
  height: 680px;
  padding: var(--spacing-16);
  box-sizing: border-box;
  box-shadow: var(--shadow-default);
  border-radius: var(--radius-l);
  background-color: var(--surface-neutral-low);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleArea = styled.div`
  height: 220px;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.h1`
  ${WHFontCss.labelMedium}
  color: var(--text-neutral-primary);
  text-align: center;
  margin-top: 32px;
  white-space: pre-wrap;
`;

const InputArea = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 var(--spacing-16);
  margin-top: var(--spacing-20);
`;

const ErrorMessageArea = styled.div`
  color: var(--text-semantic-error);
  margin-top: var(--spacing-16);
  text-align: center;
  white-space: pre-wrap;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonArea = styled.div`
  margin-top: 50px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-top: var(--spacing-24);
`;

const FullWidthButton = styled(TextButton)`
  width: 100%;
  height: 40px;
`;

const BaseTextFiled = styled(MuiTextField)`
  height: 61px;
  border: 2px;
  margin-bottom: 2px;
  ${WHFont.titleSmall}
  & .MuiInput-underline:before {
    border-bottom: 2px solid var(--border-on-light-default);
    color: var(--text-neutral-secondary);
    ${WHFont.titleSmall}
  }
  & .MuiInputLabel-root {
    opacity: 0.8;
  }
`;

const dictDef = {
  errorMessage: {
    default: {
      default: 'メールアドレスまたは組織コードが違うか\n外部アカウントとの連携が完了していません',
      [Locale.en_US]: 'The email or organization code is incorrect\nor the external account is not linked',
    },
  },
  descriptionText: {
    default: {
      default: `ご登録されたメールアドレス\nまたは組織コードを入力してください`,
      [Locale.en_US]: 'Please enter your registered email address\nor organization code',
    },
  },
  loginWithExternalId: {
    default: {
      default: '外部IDでログイン',
      [Locale.en_US]: 'Login with external ID',
    },
  },
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel.',
    },
  },
  emailOrOrgCode: {
    default: {
      default: 'メールアドレスまたは組織コード',
      [Locale.en_US]: 'Email or organization code',
    },
  },
};

const emailSchema = z.string().email();

const LoginWithExternalIdScreen = React.memo(function ResetPasswordScreen(props: P) {
  const dict = useDict(dictDef);
  const {transitionLoginScreen, email} = props;
  const [inputValue, setInputValue] = useState(email);
  const [errorMessage, setErrorMessage] = useState('');
  const [running, setRunning] = useState(false);
  const onChangeInputValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value),
    [setInputValue]
  );
  const loginWithExternalId = useCallback(async () => {
    setRunning(true);
    try {
      const flowResult: OidcStartFlowRes | Omit<OidcStartFlowRes, 'peopleId'> | SamlStartFlowRes = await (async () => {
        const trimmedInputValue = inputValue.trim();
        const isEmail = emailSchema.safeParse(trimmedInputValue).success;

        const ssoServices: SearchSsoServicesByOrgCodeResponse['services'] = await (async () => {
          if (isEmail) {
            const result = (await ApiWorkhubCoreSso.getServicesByEmail(trimmedInputValue)).services;
            const {oidc, saml} = result;
            // getServicesByEmail はもともとアプリ用にあったAPIを転用していて、新しく作った searchSsoServicesByOrgCodeApi の型に以下で揃えています
            return [
              ...oidc.map(service => ({
                organizationId: service.organizationId,
                idpName: service.idpName as 'hhCross' | 'hillsId',
                type: 'oidc' as const,
              })),
              ...saml.map(service => ({
                organizationId: service.organizationId,
                code: service.code,
                type: 'saml' as const,
              })),
            ];
          } else {
            return (await searchSsoServicesByOrgCodeApi({query: {orgCode: trimmedInputValue}})).data.services;
          }
        })();

        // oidcがあったらoidcとみなし、samlがあったらsamlとみなす、併用していて両方あるケースは想定していないので、oidcが先でないといけない理由は特にない
        const ssoService = ssoServices.find(s => s.type === 'oidc') || ssoServices.find(s => s.type === 'saml');

        if (!ssoService) {
          throw new Error('not found ssoServices');
        }

        switch (ssoService.type) {
          case 'oidc': {
            // redirectUriはPOSTでパラメータ渡すときにエンコードすると二重エンコードになるので、ここではエンコードしない
            const oidcStartFlowRes = await (async () => {
              if (isEmail) {
                return await OidcSsoService.startLoginFlow({
                  email: trimmedInputValue,
                  redirectUri: import.meta.env.VITE_SSO_REDIRECT_URL,
                });
              } else {
                return await OidcSsoService.startLoginFlowWithOrgCode({
                  code: trimmedInputValue,
                  redirectUri: import.meta.env.VITE_SSO_REDIRECT_URL,
                });
              }
            })();
            return {
              ...oidcStartFlowRes,
              type: 'oidc',
            };
          }
          case 'saml': {
            return {
              type: 'saml',
              code: ssoService.code,
              loginUrl: `${import.meta.env.VITE_WORKHUB_PATH}/sso/${ssoService.code}`,
            };
          }
        }
      })();

      if (!flowResult) {
        throw new Error('not found flowResult');
      }

      addCookieValueByKey({
        key: SsoKeys.SsoIdProvider,
        value: (() => {
          switch (flowResult.type) {
            case 'oidc':
              return flowResult.ssoIdProvider;
            case 'saml':
              return '';
          }
        })(),
      });
      addCookieValueByKey({
        key: SsoKeys.Code,
        value: (() => {
          switch (flowResult.type) {
            case 'oidc':
              return '';
            case 'saml':
              return flowResult.code;
          }
        })(),
      });
      addCookieValueByKey({
        key: SsoKeys.Operation,
        value: SsoOperations.Signin,
      });

      // NOTE: 2024年3月現在、web側のOIDCのログインはhhCrossのみ対応している。そのため、他のOIDCプロバイダーの場合はエラーを返す
      if (flowResult.type === 'oidc' && flowResult.ssoIdProvider !== SsoIdProviders.HhCross) {
        throw new Error('ssoIdProvider id not hhCross');
      }
      const {loginUrl} = flowResult;
      if (!loginUrl) {
        throw new Error('No sso login url');
      }

      window.location.href = loginUrl;
    } catch {
      setErrorMessage(dict.errorMessage);
    } finally {
      setRunning(false);
    }
  }, [dict.errorMessage, inputValue]);

  return (
    <LoginFormArea>
      {running && <WLoadingComponent message={''} />}
      <TitleArea>
        <WorkhubLogoIcon size={120} />
      </TitleArea>
      <Heading>{dict.descriptionText}</Heading>
      <InputArea>
        <InputWrapper>
          <BaseTextFiled
            id={'emailOrOrgCode'}
            label={dict.emailOrOrgCode}
            value={inputValue}
            onChange={onChangeInputValue}
            fullWidth
            variant='standard'
          />
        </InputWrapper>
        <ButtonArea>
          <ButtonWrapper>
            <FullWidthButton color={'secondary'} onClick={loginWithExternalId} disabled={!inputValue || running}>
              {dict.loginWithExternalId}
            </FullWidthButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <FullWidthButton color={'ghost'} onClick={transitionLoginScreen} disabled={running}>
              {dict.cancel}
            </FullWidthButton>
          </ButtonWrapper>
        </ButtonArea>
        {errorMessage && <ErrorMessageArea role={'alert'}>{dict.errorMessage}</ErrorMessageArea>}
      </InputArea>
    </LoginFormArea>
  );
});

export default LoginWithExternalIdScreen;
