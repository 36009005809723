import {useId} from '@react-aria/utils';
import {useVisuallyHidden} from '@react-aria/visually-hidden';
import clsx from 'clsx';
import type {FC, ReactElement} from 'react';
import {InputContext} from 'react-aria-components';

import styles from './FieldSet.module.css';
import {FieldDescription, FieldError, FieldLabel} from './FieldParts.js';

type ItemProps = {
  label: string;
  children: ReactElement;
};

export const FieldSetItem: FC<ItemProps> = ({label, children}) => {
  const labelId = useId();
  const {visuallyHiddenProps} = useVisuallyHidden();

  return (
    <div>
      <label {...visuallyHiddenProps} id={labelId}>
        {label}
      </label>
      {children}
    </div>
  );
};

type Props = {
  /** 項目のタイトル */
  label: string;
  /** 項目の説明文 */
  description?: string | undefined;
  /** 項目のヒント. (?) アイコンで表示され, フォーカスするとツールチップが表示される */
  hint?: string | undefined;
  /** 必須項目かどうか */
  required?: boolean | undefined;
  /** 項目のエラーメッセージ */
  errorMessage?: string | undefined;
  className?: string | undefined;
  children: ReadonlyArray<ReactElement<ItemProps, typeof FieldSetItem>>;
};

/**
 * フォームにおける複数の入力を持つフィールド.
 * 単一の入力の場合は Field を使う.
 */
const FieldSetRoot: FC<Props> = ({label, description, hint, errorMessage, required, className, children}) => {
  const legendId = useId();
  const descriptionId = useId();
  const errorMessageId = useId();

  return (
    <fieldset className={clsx(styles.root, className)}>
      <FieldLabel as='legend' id={legendId} required={required} hint={hint}>
        {label}
      </FieldLabel>
      {description && <FieldDescription id={descriptionId}>{description}</FieldDescription>}
      <div className={styles.items}>
        <InputContext.Provider
          value={{
            'aria-invalid': !!errorMessage,
            'aria-describedby': errorMessage ? errorMessageId : description ? descriptionId : undefined,
          }}
        >
          {children}
        </InputContext.Provider>
      </div>
      {errorMessage && <FieldError id={errorMessageId}>{errorMessage}</FieldError>}
    </fieldset>
  );
};

export const FieldSet = Object.assign(FieldSetRoot, {
  Item: FieldSetItem,
});
