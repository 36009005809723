import {useLocalizedStringFormatter} from '@react-aria/i18n';
import type {FC, ReactNode} from 'react';
import clsx from 'clsx';

import {Hint} from '../hint';
import styles from './FieldParts.module.css';

const dictionary = {
  'en-US': {
    required: 'Required',
    optional: 'Optional',
  },
  'ja-JP': {
    required: '必須',
    optional: '任意',
  },
} as const;

type LabelProps = {
  children: ReactNode;
  required?: boolean | undefined;
  hint?: string | undefined;
  id?: string | undefined;
  as?: 'label' | 'legend';
};

export const FieldLabel: FC<LabelProps> = ({children, required, hint, id, as: Element = 'label'}) => {
  const formatter = useLocalizedStringFormatter(dictionary);

  return (
    <Element id={id} className={styles.root}>
      {children}
      {required ? (
        <span className={clsx(styles.badge, styles.required)}>{formatter.format('required')}</span>
      ) : (
        <span className={clsx(styles.badge, styles.optional)}>{formatter.format('optional')}</span>
      )}
      {hint && <Hint>{hint}</Hint>}
    </Element>
  );
};

type DescriptionProps = {
  children: ReactNode;
  id?: string | undefined;
};

export const FieldDescription: FC<DescriptionProps> = ({children, id}) => {
  return (
    <span id={id} className={styles.help}>
      {children}
    </span>
  );
};

type ErrorProps = {
  children: ReactNode;
  id?: string | undefined;
};

export const FieldError: FC<ErrorProps> = ({children, id}) => {
  return (
    <span id={id} className={styles.error}>
      {children}
    </span>
  );
};
