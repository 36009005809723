import * as Icon from '@bitkey-service/workhub-icons';
import clsx from 'clsx';
import {type ComponentProps, forwardRef, type ForwardRefRenderFunction, type PropsWithChildren} from 'react';
import {Button as ButtonBase} from 'react-aria-components';

import styles from './TextButton.module.css';
import {Loading} from '../loading';
import {Ripple} from '../ripple';

type FormProps = Pick<
  ComponentProps<typeof ButtonBase>,
  'type' | 'form' | 'formAction' | 'formEncType' | 'formMethod' | 'formNoValidate' | 'formTarget' | 'name' | 'value'
>;

type Props = FormProps &
  PropsWithChildren<{
    color?: 'primary' | 'secondary' | 'disruptive' | 'ghost';
    processing?: boolean;
    done?: boolean;
    disabled?: boolean;
    className?: string;
    onClick?: () => void;
  }>;

const _TextButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  {color = 'primary', processing = false, done = false, disabled, className, onClick, children, ...props},
  ref
) => {
  return (
    <ButtonBase
      {...props}
      ref={ref}
      className={clsx(styles.root, className)}
      isDisabled={disabled}
      data-color={color}
      data-processing={processing || null}
      data-done={done || null}
      onPress={onClick}
    >
      <span className={styles.label}>{children}</span>
      {processing && (
        <span className={styles.processing}>
          <Loading />
        </span>
      )}
      {done && (
        <span className={styles.done}>
          <Icon.Done />
        </span>
      )}
      <Ripple />
    </ButtonBase>
  );
};

export const TextButton = forwardRef(_TextButton);
